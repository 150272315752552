<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
    <div>
    </div>
</template>


<script>

export default {
  created() {
    this.$vs.loading();
    const isLoggedIn = localStorage.getItem(
        `${process.env.VUE_APP_ACCESS_TOKEN_NAME}LoggedIn`
    );
    if(!isLoggedIn){
        const brandId = this.$route.params.brandId
        const brandSlug = this.$route.query.brandSlug
        this.$router.push({ 
            path: '/login',
            query: { 
                sharedbrandId: brandId,
                sharedbrandSlug: brandSlug
            } 
        });
    }
    this.$vs.loading.close();
  }
};
</script>

<style lang="scss">
</style>
  